<template>
  <div class="d-flex flex-row search-input">
    <v-combobox
      class="search-input"
      clearable
      filled
      solo
      dense
      height="48"
      v-model="selected"
      ref="autocomplete"
      :items="getItems"
      :search-input.sync="search"
      no-filter
      :label="
        isListening ? $t('navbar.speechActive') : $t('navbar.searchPlaceholder')
      "
      :placeholder="
        isListening ? $t('navbar.speechActive') : $t('navbar.searchPlaceholder')
      "
      flat
      item-text="name"
      item-value="name"
      hide-details
      hide-no-data
      return-object
      type="search"
      @focus="onFocus"
      @blur="onBlur"
      @keydown.enter.native.prevent.stop="doSearch"
      :menu-props="{
        closeOnContentClick: true,
        contentClass: 'searches-menu'
      }"
    >
      <template v-slot:item="{ item }">
        <div
          class="d-flex w-100 primary--text"
          :class="item.type == 'FastSearch' ? 'fast-search-btn' : ''"
        >
          <v-list-item-icon
            v-if="item.type == 'Categorie'"
            class="category-img"
          >
            <img :src="getImage(item)" onerror="this.src='/no-icon.png'" />
          </v-list-item-icon>
          <v-list-item-icon v-else-if="item.type == 'Ricerche'">
            <v-icon @click.prevent.stop="deleteWord(item)" small>
              $close
            </v-icon>
          </v-list-item-icon>
          <v-list-item-icon v-else-if="item.type == 'FastSearch'">
            <v-icon>$fastSearch</v-icon>
          </v-list-item-icon>
          <v-list-item-icon v-else>
            <v-icon small>$search</v-icon>
          </v-list-item-icon>
          <v-list-item-content
            @click="
              item.type == 'FastSearch'
                ? openFastSearchDialog()
                : doSearch(item)
            "
          >
            <v-list-item-title>
              <span
                class="font-weight-bold primary--text text-body-2"
                v-if="item.type == 'FastSearch'"
              >
                {{ $t("fastSearch.searchInput.label") }}
              </span>
              <text-highlight v-else :queries="search ? search : '*'">
                {{ item.name }}
              </text-highlight>
            </v-list-item-title>
            <v-list-item-subtitle v-if="item.type == 'Categorie'">
              Cerca <strong>{{ search }}</strong> in {{ item.name }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.type == 'Marche'">
              Cerca <strong>{{ search }}</strong> con marca {{ item.name }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="my-0">
            <div class="badge" v-if="item.count">
              {{ item.count }}
            </div>
            <v-icon
              v-if="item.type == 'Ricerche'"
              @click.prevent.stop="search = item.name"
              small
            >
              $arrowTopLeft
            </v-icon>
          </v-list-item-action>
        </div>
      </template>
      <template slot="append">
        <!-- <v-icon
          v-if="isCordova"
          @click.prevent.stop="scan()"
          color="primary"
          @input="doSearch"
          >$qrCode</v-icon
        > -->
        <Barcode v-if="isCordova" @input="doSearch" />
        <v-icon
          color="black"
          aria-label="Cerca"
          @click.prevent.stop="doSearch"
          v-else
          >$search</v-icon
        >
      </template>
    </v-combobox>
  </div>
</template>
<style lang="scss">
.search-input {
  .v-autocomplete {
    font-size: 13px;
    &.search-input {
      border: 1px solid var(--v-primary-base);
    }
    ::placeholder,
    .v-label {
      font-size: 13px !important;
      color: var(--v-black) !important;
    }
    .v-input__slot {
      height: 48px;
      border: 1px solid (--v-grey-base);
      .v-label {
        top: calc(50% - 9px) !important;
      }
    }
  }
  .v-autocomplete__content {
    .v-list--dense {
      .v-subheader {
        font-size: 16px;
        font-weight: bold;
        color: $primary;
        padding: 0px 16px;
      }
      .v-list-item {
        min-height: 32px;
        .v-list-item__icon {
          padding: 0;
          margin-top: 4px;
          margin-bottom: 4px;
        }
      }
    }
    .fast-search-btn {
      border-bottom: 1px solid var(--v-grey-lighten2);
    }
  }
  .search-input.v-select.v-select--is-menu-active {
    .v-input__control {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
  .search-item {
    min-height: 36px;
  }
  .category-img {
    margin-top: 11px !important;
    width: 31px;
  }
  .searches-menu.v-autocomplete__content.v-menu__content {
    max-height: calc(100vh - 200px) !important;
  }
}
.is-cordova {
  .v-menu__content.v-autocomplete__content.searches-menu {
    width: 100%;
    left: 0;
    z-index: 10;
  }
}
</style>
<script>
import Barcode from "./Barcode.vue";
import TextHighlight from "vue-text-highlight";
import FastSearchMenu from "./FastSearchMenu.vue";

import ProductService from "~/service/productService";
import AnalyticsService from "~/service/analyticsService";

import { mapGetters, mapActions } from "vuex";

import debounce from "lodash/debounce";
import forEach from "lodash/forEach";

import LongPress from "vue-directive-long-press";

export default {
  components: {
    Barcode,
    TextHighlight
  },
  data() {
    return {
      loading: false,
      isFocused: null,
      selected: null,
      search: null,
      isListening: false,
      items: [],
      menuProps: {
        closeOnContentClick: true,
        openOnClick: true
      },
      speechOptions: {
        language: "it-IT",
        matches: "5",
        prompt: "", // Android only
        showPopup: true, // Android only
        showPartial: false
      }
    };
  },
  watch: {
    search(val, oldVal) {
      if (val !== oldVal && val && val.length > 3) {
        this.fetchSuggestion();
      }
    }
  },
  computed: {
    ...mapGetters({
      commonSearch: "wordsSearched/commonSearch"
    }),
    getItems() {
      let result = this.search ? this.items : this.commonSearch;
      result.unshift({
        type: "FastSearch",
        name: "Spesa veloce"
      });
      return result;
    }
  },
  directives: {
    "long-press": LongPress
  },
  methods: {
    ...mapActions({
      saveWord: "wordsSearched/saveWord",
      deleteWord: "wordsSearched/deleteWord"
    }),
    getImage(item) {
      return "/img_layout/categories_icon/" + item.id + ".svg";
    },
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
    fetchSuggestion: debounce(async function() {
      let _this = this;
      this.loading = true;
      if (this.search) {
        await ProductService.suggest(this.search.trim()).then(function(
          response
        ) {
          let newItems = [];
          let prevType = "none";

          forEach(response, function(value) {
            if (value.type != prevType) {
              newItems.push({ header: value.type });
              prevType = value.type;
            }
            newItems.push(value);
          });
          _this.items = newItems;
          _this.loading = false;
        });
      }
    }, 200),
    doSearch(item) {
      this.items = [];

      var currentQuery = {};
      if (item.type === "Suggerimenti") {
        //ho selezionato un nome
        currentQuery.q = item.name;
      } else if (item.type === "Categorie") {
        currentQuery.parent_category_id = item.id;
        currentQuery.filter = item.name;
        currentQuery.q = this.search;
      } else if (item.type === "Ricerche") {
        currentQuery.q = item.name;
      } else if (item.type === "Marche") {
        currentQuery.parent_vendor_id = item.id;
        currentQuery.filter = item.name;
        currentQuery.q = this.search;
      } else if (item.type === "Barcode") {
        currentQuery.barcode = item.name;
      } else if (item.type === "FastSearch") {
        currentQuery.q = "";
      } else if (this.search != null) {
        currentQuery.q = this.search;
        this.saveWord(currentQuery.q);
      }
      if (currentQuery.q) {
        AnalyticsService.search(currentQuery.q);
      } else if (currentQuery.barcode) {
        AnalyticsService.search("barcode");
      }

      if (currentQuery.q || currentQuery.barcode) {
        //AnalyticsService.search(currentQuery.q);
        //this.$store.dispatch("category/resetFilters");
        global.EventBus.$emit("resetFilters");
        this.$router.push({
          // path: "/search?q=:q&parent_category_id=:parent_category_id",
          path: "/search",
          name: "Search",
          query: currentQuery
        });

        this.$nextTick(() => {
          this.$refs.autocomplete.blur();
          this.search = currentQuery.q;
        });
      }
    },
    onLongPressStart() {
      // triggers after 300ms of mousedown
      this.isListening = true;
      this.startSpeechRecognition();
    },
    onLongPressStop() {
      // triggers on mouseup of document
      this.isListening = false;
      this.stopSpeechRecognition();
    },
    startSpeechRecognition() {
      // Verify if recognition is available
      var _this = this;
      // eslint-disable-next-line no-undef
      // let platform = device ? device.platform.toLowerCase() : "ios";
      window.plugins.speechRecognition.isRecognitionAvailable(
        function(available) {
          if (!available) {
            console.log("Riconoscimento Vocale non disponibile");
          }

          // Check if has permission to use the microphone
          window.plugins.speechRecognition.hasPermission(
            function(isGranted) {
              if (isGranted) {
                window.plugins.speechRecognition.startListening(
                  function(result) {
                    // Show results in the console
                    console.log(result);
                    _this.search = result[0];
                    _this.doSearch(result[0]);
                  },
                  function(err) {
                    console.error(err);
                  },
                  _this.speechOptions
                );
              } else {
                // Request the permission
                window.plugins.speechRecognition.requestPermission(
                  function() {
                    // Request accepted, start recognition
                    window.plugins.speechRecognition.startListening(
                      function(result) {
                        // Show results in the console
                        console.log(result);
                        _this.search = result[0];

                        _this.doSearch(result[0]);
                      },
                      function(err) {
                        console.error(err);
                      },
                      _this.speechOptions
                    );
                  },
                  function(err) {
                    console.log(err);
                  }
                );
              }
            },
            function(err) {
              console.log(err);
            }
          );
        },
        function(err) {
          console.log(err);
        }
      );
    },
    openFastSearchDialog() {
      this.$emit("closeSearchModal", true);
      var defaultConfig = {
        toolbarColor: "secondary",
        fullscreen: this.$vuetify.breakpoint.smAndDown
      };
      let properties = {
        waitForResult: true,
        width: 650
      };
      var config = Object.assign({}, properties, defaultConfig);
      this.dialogInstance = this.$dialog.show(FastSearchMenu, config);
    },
    stopSpeechRecognition() {
      window.plugins.speechRecognition.stopListening(
        function() {
          // No more recognition
        },
        function(err) {
          console.log(err);
        }
      );
    }
    // scan() {
    //   if (window.cordova && window.cordova.plugins.barcodeScanner) {
    //     try {
    //       this.loading = true;
    //       // eslint-disable-next-line no-undef
    //       cordova.plugins.barcodeScanner.scan(
    //         result => {
    //           if (result) {
    //             this.loading = false;
    //             this.$emit("input", {
    //               type: "Barcode",
    //               name: result.text
    //             });
    //           }
    //         },
    //         error => {
    //           this.loading = false;
    //           console.error("Scanning failed", error);
    //         },
    //         {
    //           showFlipCameraButton: true,
    //           showTorchButton: true,
    //           resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
    //           formats:
    //             this.formats ||
    //             "EAN_8,EAN_13,CODE_128,CODE_39,CODE_93,CODABAR,UPC_A,UPC_E"
    //         }
    //       );
    //     } catch (err) {
    //       console.log(err);
    //     }
    //   }
    // }
  },
  mounted() {
    global.EventBus.$on("clearSearch", () => {
      this.search = null;
      this.selected = null;
    });
  },
  beforeDestroy() {
    global.EventBus.$off("clearSearch");
  }
};
</script>
